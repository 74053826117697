<template>
  <div class="track">
    <div class="cover-container">
      <div class="cover">
        <div class="row justify-content-center mx-0">
          <div class="col-md-12 px-0">
            <div class="top-navbar px-3 pt-2">
              <div class="d-flex">
                <a
                  @click.prevent="
                    $store.commit('UPDATE_PLAYED_TRACKLIST_MODAL_SHOW', true)
                  "
                  class="ripple ripple-surface cursor-pointer"
                  ><i class="la la-file-audio la-2x card-title p-3"></i></a>
                <div class="col"></div>
                <a
                  @click.prevent="goBack()"
                  class="ripple ripple-surface cursor-pointer"
                  ><i class="la la-angle-down la-lg card-title p-3"></i
                ></a>
              </div>
              <div class="image mt-4 px-3" v-if="!$apollo.queries.track.loading">
                <img :src="currentTrack.cover_url" class="" />
              </div>
            </div>
            <div class="header mt-3">
              <div v-if="$apollo.queries.track.loading" class="text-center"><i class="la la-spin la-spinner la-2x"></i></div>

              <div class="d-flex px-3" v-if="!$apollo.queries.track.loading" :disabled="!$store.getters.user">
                <div class="text-center">
                  <span @click="onFavoriteClick" class="cursor-pointer d-block mb-3">
                    <i
                      v-if="isItInMyFavorites"
                      class="
                        ripple ripple-surface
                        la la-heart la-2x
                        text-danger
                        p-3
                      "
                    ></i>
                    <i
                      v-else
                      class="
                        ripple ripple-surface
                        la la-heart-o la-2x
                        text-danger
                        p-3
                      "
                    ></i>
                  </span>
                  <!-- <a href="" target="_blank" class="d-block mb-2"><i class="la px-2 la-facebook-square la-2x text-muted mx-1"></i></a>
                  <a href="" target="_blank" class="d-block mb-2"><i class="la px-2 la-twitter la-2x text-muted mx-1"></i></a>
                  <a href="" target="_blank" class="d-block mb-2"><i class="la px-2 la-whatsapp la-2x text-muted mx-1"></i></a> -->
                </div>

                <div class="col px-3 text-center pt-2">
                  <div class="font-weight-bolder h4 mb-2 card-title">
                    {{ currentTrack.name_ar }}
                  </div>
                  <div class="text-sm mb-1">
                    <span
                      v-for="(author, index) in currentTrack.authors"
                      :key="author.id"
                    >
                      {{ author.name_ar }}
                      <span
                        v-if="
                          index != Object.keys(currentTrack.authors).length - 1
                        "
                        class="mx-2 font-weight-bold"
                        >·
                      </span>
                    </span>
                  </div>
                  <div class="mt-4 ">
                    <a @click.prevent="$store.commit('UPDATE_PLAYED_TRACKLIST_MODAL_SHOW', true)" class="ripple ripple-surface cursor-pointer text-decoration-underline">
                      <i class="la la-file-audio card-title px-2"></i>
                      <span class="card-title" v-if="$store.getters.currentTracklistName && $store.getters.currentTracklistName != 'undefined'">{{ $store.getters.currentTracklistName }}</span>
                      <span class="card-title" v-else>{{ 'قائمة التشغيل' }}</span>
                    </a>
                  </div>
                  <div class="mt-4">
                      <a :href="socialShareLinks.facebook" target="_blank"><i class="la la-facebook-square la-2x text-muted mx-1"></i></a>
                      <a :href="socialShareLinks.twitter" target="_blank"><i class="la la-twitter la-2x text-muted mx-1"></i></a>
                      <a :href="socialShareLinks.whatsapp" target="_blank"><i class="la la-whatsapp la-2x text-muted mx-1"></i></a>
                  </div>
                  <div class="mt-4" v-if="!$store.getters.user || !$store.getters.user.subscription_model.has_active_billing">
                    <i class="la la-info-circle la-2x text-danger"></i>
                    <div class="text-danger"> إشتراك بالخدمة غير نشط، الرجاء شحن رصيدك للإستمتاع بالخدمة</div>
                  </div>
                </div>
                <div class="">
                  <!-- Add to playlist button -->
                  <span class="cursor-pointer" @click="addToPlaylistModalShow++" :disabled="!$store.getters.user" >
                      <i class="ripple ripple-surface la la-plus-square la-2x p-3"></i>
                  </span>
                  <AddToPlaylistModal :key="addToPlaylistModalShow" v-if="addToPlaylistModalShow" :track="track"  @close="addToPlaylistModalShow = 0" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";
import AddToPlaylistModal from '../../components/Player/playlist/AddToPlaylistModal.vue';

export default {
  components: { AddToPlaylistModal, },

  data: () => {
    return {
      track: {},
      isItInMyFavorites: false,
      addToPlaylistModalShow: 0,
      socialShareLinks: {}
    }
  },

  created () {
    const trackURL = window.location.origin + this.$route.fullPath;
    this.socialShareLinks = {
      facebook: 'https://web.facebook.com/sharer.php?u=' + trackURL,
      twitter: 'https://twitter.com/intent/tweet?text=' + this.currentTrack.name_ar + '&url=' + trackURL,
      whatsapp: 'whatsapp://send?text=' + this.currentTrack.name_ar + ' ' + trackURL
    }
  },

  computed: {
    currentTrack() {
      if (this.$store.getters.currentTrack) {
        return this.$store.getters.currentTrack;
      }
      return this.track;
    },
  },

  watch: {
    track(val) {
      let userID = this.$store.getters.user? this.$store.getters.user.id ?? null: null;
      if(val.favorites && userID) {
        this.track.favorites.forEach(f => {
          if(f.user.id == userID) {
            this.isItInMyFavorites = true
          }
        })
      }
    }
  },

  methods: {
    hasHistory() {
      return window.history.length > 2;
    },
    goBack() {
      if (this.hasHistory()) {
        this.$router.go(-1);
      } else {
        this.$router.push({ name: "Home" });
      }
    },

    onFavoriteClick() {
      this.isItInMyFavorites = !this.isItInMyFavorites // For best user experience
      this.$apollo
        .mutate({
          mutation: gql`
            mutation ($id: ID!) {
              toggleFavorite(id: $id) {
                id
              }
            }
          `,
          // Parameters
          variables: {
            id: this.track.id,
          },
        })
        .then((data) => {
          // Result
          // console.log(data.data.toggleFavorite)
          // this.isItInMyFavorites = !this.isItInMyFavorites
        });
    },
  },

  apollo: {
    track: {
      query: gql`
        query Provider($id: ID) {
          track(id: $id) {
            id
            name_ar
            name_en
            description_ar
            description_en
            thumbnail_url
            cover_url
            full_url

            favorites {
              user {
                id
              }
            }

            authors {
              id
              name_ar
              name_en
            }

            categories {
              id
              name_ar
              name_en
            }
          }
        }
      `,
      variables() {
        return {
          id: this.$route.params.id,
        };
      },
      fetchPolicy: 'cache-and-network',
    },
  },

  mounted() {
    this.$store.commit("UPDATE_MINIMIZED_PLAYER_SHOW", false);
  },

  unmounted() {
    this.$store.commit("UPDATE_MINIMIZED_PLAYER_SHOW", true);
  },
};
</script>

<style>
</style>